import { useQuery } from '@apollo/client';
import EmailIcon from '@mui/icons-material/Email';
import { Alert, Box, Button, Paper, Stack, Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { sv as locale } from '@norban/locale';
import { LeadTemplate } from '@norban/utils/dist';
import React, { useState } from 'react';

import EmailDialog from '../../../components/EmailDialog';
import {
  BofLeadsTemplatesDocument,
  BulkEmailType,
  BulkEmailUnsubscribeType,
} from '../../../generated/backend/graphql';

type Props = {
  userId: string;
  homeId?: string;
  email?: string;
  assignedAgentId?: string;
};

type SelectedTab = 'interest' | 'followUp' | 'onboarding' | 'hidden';

type SelectedTabMap = {
  [key in SelectedTab]: { key: BulkEmailType; description: string };
};

const useStyles = makeStyles(() => ({
  message: {
    whiteSpace: 'pre-wrap',
  },
}));

const SelectedTabEmailMap: SelectedTabMap = {
  interest: {
    key: BulkEmailType.LeadTemplateInterest,
    description: 'Bearbetningsmall intresse',
  },
  followUp: {
    key: BulkEmailType.LeadTemplateFollowUp,
    description: 'Bearbetningsmall uppföljning',
  },
  onboarding: {
    key: BulkEmailType.LeadTemplateOnboarding,
    description: 'Bearbetningsmall onboarding',
  },
  hidden: {
    key: BulkEmailType.LeadTemplateHidden,
    description: 'Bearbetningsmall dold',
  },
};

const LeadTemplates = ({
  userId,
  homeId = undefined,
  email = undefined,
  assignedAgentId = undefined,
}: Props) => {
  const L = locale.backoffice.leadTemplates;
  const classes = useStyles();
  const [currentTab, setCurrentTag] = useState<SelectedTab>('interest');
  const [dialogOpen, setDialogOpen] = useState(false);

  const { data, loading, error } = useQuery(BofLeadsTemplatesDocument, {
    variables: { id: currentTab, userId },
    skip: !email,
  });
  const { leadTemplate } = data || {};
  const numHomeId = homeId ? parseInt(homeId, 10) : undefined;

  if (!homeId) {
    return (
      <Paper>
        <Alert severity="warning">
          Bearbetningsmallar kan bara användas för användare med registerad
          bostad
        </Alert>
      </Paper>
    );
  }

  return (
    <Paper>
      <EmailDialog
        defaultType={SelectedTabEmailMap[currentTab].key}
        recipients={[{ id: userId, email: email ?? '' }]}
        userFilter={{}}
        userFilterCount={-1}
        open={dialogOpen}
        onSent={() => {}}
        onClose={() => setDialogOpen(false)}
        lookupUserId={assignedAgentId}
        typeDatas={[
          {
            type: SelectedTabEmailMap[currentTab].key,
            // FIXME - should be none
            unsubscribeType: BulkEmailUnsubscribeType.Marketing,
            description: SelectedTabEmailMap[currentTab].description,
            // FIXME - error handling
            subject: leadTemplate?.subject ?? '',
            message: leadTemplate?.message ?? '',
            signatureType: 'withLogo',
          },
        ]}
        skipFirstTwoSteps
        homeId={numHomeId}
      />
      <Tabs
        variant="scrollable"
        value={currentTab}
        onChange={(_event, newValue) => setCurrentTag(newValue)}
      >
        <Tab key="interest" value="interest" label={L.interest} />
        <Tab key="followUp" value="followUp" label={L.followUp} />
        <Tab key="onboarding" value="onboarding" label={L.onboarding} />
        <Tab key="hidden" value="hidden" label={L.hidden} />
      </Tabs>
      <Box p={2}>
        <Stack>
          <Box mb={2}>
            {/* horizontal stack */}
            <Stack direction="row" spacing={2}>
              <Button
                color="secondary"
                size="small"
                variant="contained"
                startIcon={<EmailIcon />}
                onClick={() => setDialogOpen(true)}
              >
                Skicka mejl
              </Button>
            </Stack>
          </Box>
          {(error || !email) && (
            <Alert severity="error">
              {error ? error.message : 'Användaren saknar email'}
            </Alert>
          )}
          {data?.leadTemplate.warnings.length != null &&
            data.leadTemplate.warnings.length > 0 && (
              <Alert severity="warning">
                <strong>
                  {`Viss information nedan saknas eller kan vara felaktig: 
                  ${data.leadTemplate.warnings
                    .map(warning => L[warning as keyof LeadTemplate])
                    .join(', ')}`}
                </strong>
              </Alert>
            )}
          {data?.leadTemplate.warnings.length != null &&
            data.leadTemplate.warnings.length === 0 && (
              <Alert severity="success">
                <strong>Meddelandet är fullständigt!</strong>
              </Alert>
            )}
          <pre className={classes.message}>
            {loading && 'Laddar...'}
            {!loading && !error && <span>{leadTemplate?.message}</span>}
          </pre>
        </Stack>
      </Box>
    </Paper>
  );
};

export default LeadTemplates;
